<template>
    <div>
        <LiefengContent>
            <template v-slot:title>广州市用户增长情况</template>
            <template v-slot:toolsbarLeft>
                <div class="btn-div">
                    <div v-for="(item, index) in menuList" :key="index" :class="menuIndex == index ? 'item active' : 'item'" @click="chooseItem(index)">{{ item.name }}</div>
                    <div>
                        <DatePicker v-model="year" type="year" format="yyyy" placeholder="选择年" style="width: 200px" @on-change="changeTime" />
                    </div>
                    <div class="time" style="margin-left: 20px">更新时间：{{ updateDate }}</div>
                </div>
            </template>
            <template v-slot:toolsbarRight>
                <Button type="primary" @click="exportExcel">导出报表</Button>
            </template>
            <template v-slot:contentArea>
                <LiefengTable
                    :talbeColumns="menuIndex == 0 ? talbeColumns : menuIndex == 1 ? talbeColumns2 : menuIndex == 2 ? talbeColumns3 : ''"
                    :tableData="tableData"
                    :loading="loading"
                    :fixTable="true"
                    :curPage="page"
                    :total="total"
                    :pagesizeOpts="[20, 30, 50, 100]"
                    :page-size="pageSize"
                    @hadlePageSize="hadlePageSize"
                    :hidePage="true"
                    ref="table"
                ></LiefengTable>
            </template>
        </LiefengContent>
    </div>
</template>


<script>
import LiefengContent from "@/components/LiefengContent3"
import LiefengTable from "@/components/LiefengTable"
export default {
    data() {
        return {
            talbeColumns: [
                {
                    title: "按区",
                    align: "center",
                    minWidth: 200,
                    children: [
                        {
                            title: "区",
                            align: "center",
                            width: 100,
                            key: 0,
                        },
                    ],
                },
                {
                    title: "按月统计近一年的用户数",
                    align: "center",
                    minWidth: 200,
                    children: this.getYearMonth(0),
                },
                {
                    title: "按季度统计近一年的用户数",
                    align: "center",
                    minWidth: 200,
                    children: this.getSeason(0),
                },
            ],
            talbeColumns2: [
                {
                    title: "按街道",
                    align: "center",
                    minWidth: 200,
                    children: [
                        {
                            title: "区",
                            align: "center",
                            width: 100,
                            key: 0,
                        },
                        {
                            title: "街道",
                            align: "center",
                            width: 100,
                            key: 1,
                        },
                    ],
                },
                {
                    title: "按月统计近一年的用户数",
                    align: "center",
                    minWidth: 200,
                    children: this.getYearMonth(1),
                },
                {
                    title: "按季度统计近一年的用户数",
                    align: "center",
                    minWidth: 200,
                    children: this.getSeason(1),
                },
            ],
            talbeColumns3: [
                {
                    title: "按社区",
                    align: "center",
                    minWidth: 200,
                    children: [
                        {
                            title: "区",
                            align: "center",
                            width: 100,
                            key: 0,
                        },
                        {
                            title: "街道",
                            align: "center",
                            width: 100,
                            key: 1,
                        },
                        {
                            title: "社区",
                            align: "center",
                            width: 100,
                            key: 2,
                        },
                    ],
                },
                {
                    title: "按月统计近一年的用户数",
                    align: "center",
                    minWidth: 200,
                    children: this.getYearMonth(2),
                },
                {
                    title: "按季度统计近一年的用户数",
                    align: "center",
                    minWidth: 200,
                    children: this.getSeason(2),
                },
            ],
            tableData: [],
            loading: false,
            page: 1,
            pageSize: 20,
            total: 0,

            menuList: [{ name: "按区" }, { name: "按街道" }, { name: "按社区" }],
            menuIndex: 0,
            menuName: "按区",

            updateDate: "",

            monthList: [],
            seasonList: [],
            showTable: false,
            year: "2024",
        }
    },
    methods: {
        changeTime(val) {
            this.year = this.$core.formatDate(new Date(val), "yyyy")
            this.chooseItem(this.menuIndex)
            if (this.menuIndex == 0) {
                this.talbeColumns = []
                this.talbeColumns = [
                    {
                        title: "按区",
                        align: "center",
                        minWidth: 200,
                        children: [
                            {
                                title: "区",
                                align: "center",
                                width: 100,
                                key: 0,
                            },
                        ],
                    },
                    {
                        title: "按月统计近一年的用户数",
                        align: "center",
                        minWidth: 200,
                        children: this.getYearMonth(0),
                    },
                    {
                        title: "按季度统计近一年的用户数",
                        align: "center",
                        minWidth: 200,
                        children: this.getSeason(0),
                    },
                ]
            } else if (this.menuIndex == 1) {
                this.talbeColumns2 = []
                this.talbeColumns2 = [
                    {
                        title: "按街道",
                        align: "center",
                        minWidth: 200,
                        children: [
                            {
                                title: "区",
                                align: "center",
                                width: 100,
                                key: 0,
                            },
                            {
                                title: "街道",
                                align: "center",
                                width: 100,
                                key: 1,
                            },
                        ],
                    },
                    {
                        title: "按月统计近一年的用户数",
                        align: "center",
                        minWidth: 200,
                        children: this.getYearMonth(1),
                    },
                    {
                        title: "按季度统计近一年的用户数",
                        align: "center",
                        minWidth: 200,
                        children: this.getSeason(1),
                    },
                ]
            } else if (this.menuIndex == 2) {
                this.talbeColumns3 = []
                this.talbeColumns3 = [
                    {
                        title: "按社区",
                        align: "center",
                        minWidth: 200,
                        children: [
                            {
                                title: "区",
                                align: "center",
                                width: 100,
                                key: 0,
                            },
                            {
                                title: "街道",
                                align: "center",
                                width: 100,
                                key: 1,
                            },
                            {
                                title: "社区",
                                align: "center",
                                width: 100,
                                key: 2,
                            },
                        ],
                    },
                    {
                        title: "按月统计近一年的用户数",
                        align: "center",
                        minWidth: 200,
                        children: this.getYearMonth(2),
                    },
                    {
                        title: "按季度统计近一年的用户数",
                        align: "center",
                        minWidth: 200,
                        children: this.getSeason(2),
                    },
                ]
            }
        },
        hadlePageSize(val) {
            this.page = val.page
            this.pageSize = val.pageSize
            this.getOrgList()
        },
        //   导出报表
        exportExcel() {
            this.$Modal.confirm({
                title: "导出提醒",
                content: "<p>正在导出当前表格数据，是否继续？</p>",
                onOk: () => {
                    let tHeader = []
                    let filterVal = []
                    if (this.menuIndex == 0) {
                        tHeader = ["区", ...this.monthList, ...this.seasonList]
                    } else if (this.menuIndex == 1) {
                        tHeader = ["区", "街道", ...this.monthList, ...this.seasonList]
                    } else if (this.menuIndex == 2) {
                        tHeader = ["区", "街道", "社区", ...this.monthList, ...this.seasonList]
                    }
                    this.tableData.map((item, index) => {
                        if (item && item.length != 0) {
                            item.map((items, indexs) => {
                                if (index == 0) {
                                    filterVal.push(indexs)
                                }
                            })
                        }
                    })

                    let tableData = JSON.parse(JSON.stringify(this.tableData))
                    this.$core.exportExcel(tHeader, filterVal, tableData, `广州市用户${this.menuName}增长情况`)
                },
            })
        },
        chooseItem(index) {
            this.menuIndex = index
            if (index == 0) {
                this.showTable = false
                this.menuName = "按区"
                this.getList("/statistic/api/symanage/pc/syuser/statMonthUserByZone")
            } else if (index == 1) {
                this.showTable = false
                this.menuName = "按街道"
                this.getList("/statistic/api/symanage/pc/syuser/statMonthUserByStreet")
            } else if (index == 2) {
                this.showTable = true
                this.menuName = "按社区"
                this.page = 1
                this.pageSize = 20
                this.total = 0
                this.getOrgList()
                // this.getList('/statistic/api/symanage/pc/syuser/statMonthUserByCommunity')
            }
        },

        // 获取当前月之前的十二月份
        getYearMonth(num) {
            let arr = []
            let data = new Date()
            let yearsss = Number(this.year) || new Date().getFullYear()
            data.setMonth(data.getMonth() + 1, 1) //获取到当前月份,设置
            for (var i = 0; i < 12; i++) {
                arr.push({ title: yearsss + "年" + (i + 1) + "月", align: "center", width: 100, key: num + i + 1 })
            }
            return arr
        },
        // 获取当前季度以及前三个季度
        getSeason(num) {
            let yearsss = Number(this.year) || new Date().getFullYear()
            let arr = []

            for (var i = 0; i < 4; i++) {
                arr.push({ title: yearsss + "年" + (i + 1) + "季度", align: "center", width: 100, key: num + 13 + i })
            }
            return arr
        },
        getMonth() {
            let yearsss = Number(this.year) || new Date().getFullYear()
            let data = new Date()
            this.monthList = []
            data.setMonth(data.getMonth() + 1, 1) //获取到当前月份,设置
            for (var i = 0; i < 12; i++) {
                this.monthList.push(yearsss + "年" + (i + 1) + "月")
            }
        },
        getJidu() {
            let yearsss = Number(this.year) || new Date().getFullYear()
            this.seasonList = []
            for (var i = 0; i < 4; i++) {
                this.seasonList.push(yearsss + "年" + (i + 1) + "季度")
            }
        },
        // 获取当前时间的前一天
        getUpdateDate() {
            var day1 = new Date()
            day1.setTime(day1.getTime() - 24 * 60 * 60 * 1000)
            var s1 = day1.getFullYear() + "-" + (day1.getMonth() + 1) + "-" + day1.getDate() + " " + "00:00:00"

            this.updateDate = s1
        },
        //  按区接口
        async getList(url) {
            this.loading = true
            await this.$get(url, {
                year: this.year || "",
            }).then(res => {
                this.loading = false
                if (res.code == 200) {
                    this.tableData = res.dataList
                } else {
                    this.$Message.error({
                        content: "获取数据失败",
                        background: true,
                    })
                    this.tableData = []
                    return
                }
            })
        },
        //  获取社区接口
        getOrgList() {
            this.loading = true
            this.$get("/statistic/api/symanage/pc/syuser/statMonthUserByCommunity", {
                year: this.year || "",
                // page:this.page,
                // pageSize:this.pageSize
            }).then(res => {
                this.loading = false
                if (res.code == 200) {
                    this.tableData = res.dataList
                    //   this.total = res.maxCount
                } else {
                    this.$Message.error({
                        content: "获取数据失败",
                        background: true,
                    })
                    this.tableData = []
                    return
                }
            })
        },
        //
    },
    async created() {
        await this.getList("/statistic/api/symanage/pc/syuser/statMonthUserByZone")
        this.getUpdateDate()
        this.getMonth()
        this.getJidu()
    },

    components: {
        LiefengContent,
        LiefengTable,
    },
}
</script>

<style lang="less" scoped>
.btn-div {
    display: flex;
    line-height: 30px;
    width: 1000px;
    .item {
        border: 1px solid #ccc;
        color: black;
        padding: 0 20px;
        border-radius: 5px;
        margin-right: 10px;
        cursor: pointer;
        user-select: none;
    }
    .active {
        background: #2d8cf0;
        color: #ffffff;
    }
}
/deep/ .ivu-page-item-jump-next:after,
.ivu-page-item-jump-prev:after {
    content: "\2022\2022\2022" !important;
}
</style>    